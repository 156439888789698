// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bc-value-mobile {
  display: flex;
  margin-top: 3px;
  align-items: center;
}

.pt-10 {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/boleto/new/step2/mobile/styles.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,eAAA;EACA,mBAAA;AAFF;;AAKA;EACE,iBAAA;AAFF","sourcesContent":["@use \"../../../../../../../assets/scss/colors\";\n@use \"../../../../../../../assets/scss/typography\";\n\n.bc-value-mobile {\n  display: flex;\n  margin-top: 3px;\n  align-items: center;\n}\n\n.pt-10 {\n  padding-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
