// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main-logged-area {
  background-color: #e5e5e5;
  width: 100%;
  margin: 0;
  height: auto;
  padding: 0 !important;
  color: #fff;
}

.Col-no-padding-logged {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.flex-1 {
  flex: 1 1;
}

.insurance-wrapper {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-block;
  padding-left: 75px;
  padding-right: 75px;
}

.insurance-mobile-wrapper {
  height: 100%;
  background-color: var(--k4n-primary-color);
}

.insurance-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.insurance-content {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 32px 32px;
  margin-bottom: 20px;
}

.insurance-mobile-header {
  padding: 20px;
  background-color: var(--k4n-primary-color);
}

.insurance-mobile-content {
  border-radius: 16px 16px 0 0;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/seguro/styles.scss"],"names":[],"mappings":"AAGA;EACE,yBAAA;EACA,WAAA;EACA,SAAA;EACA,YAAA;EACA,qBAAA;EACA,WAAA;AAFF;;AAKA;EACE,4BAAA;EACA,6BAAA;AAFF;;AAKA;EACE,SAAA;AAFF;;AAKA;EACE,6BAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,YAAA;EACA,0CAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,4CAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,aAAA;EACA,0CAAA;AAFF;;AAKA;EACE,4BAAA;EACA,sBAAA;AAFF","sourcesContent":["@use \"../../../../assets/scss/colors\";\n@use \"../../../../assets/scss/typography\";\n\n.Main-logged-area {\n  background-color: #e5e5e5;\n  width: 100%;\n  margin: 0;\n  height: auto;\n  padding: 0 !important;\n  color: #fff;\n}\n\n.Col-no-padding-logged {\n  padding-left: 0px !important;\n  padding-right: 0px !important;\n}\n\n.flex-1 {\n  flex: 1;\n}\n\n.insurance-wrapper {\n  background-color: transparent;\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  display: inline-block;\n  padding-left: 75px;\n  padding-right: 75px;\n}\n\n.insurance-mobile-wrapper {\n  height: 100%;\n  background-color: var(--k4n-primary-color);\n}\n\n.insurance-container {\n  width: 100%;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n}\n\n.insurance-content {\n  margin-top: 20px;\n  width: 100%;\n  background: #ffffff;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);\n  border-radius: 16px;\n  padding: 32px 32px;\n  margin-bottom: 20px;\n}\n\n.insurance-mobile-header {\n  padding: 20px;\n  background-color: var(--k4n-primary-color);\n}\n\n.insurance-mobile-content {\n  border-radius: 16px 16px 0 0;\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
