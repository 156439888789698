// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pix-receipt-content-mobile {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.pix-receipt-capture-mobile {
  padding: 10%;
}

.pix-receipt-table-mobile {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
  padding-top: 16px;
  padding-bottom: 16px;
}

.pix-receipt-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.pix-receipt-h1-mobile {
  font-family: "K4n-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #737373;
}

.border-bottom-none {
  border-bottom: none !important;
}

.pix-receipt-title-mobile {
  font-family: "K4n-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: var(--k4n-primary-color);
}

.mg-top-8 {
  margin-top: 8px !important;
}

.mg-bottom-50 {
  margin-bottom: 50px;
}

.text-align-right {
  text-align: right !important;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/Pix/PixPayment/PixReceipt/Mobile/styles.scss","webpack://./src/assets/scss/_typography.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,4CAAA;EACA,mBAAA;AAFF;;AAKA;EACE,YAAA;AAFF;;AAKA;EACE,WAAA;EACA,oBAAA;EACA,mBAAA;EACA,8BAAA;EACA,gCAAA;EACA,iBAAA;EACA,oBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,sCChCU;EDiCV,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFF;;AAKA;EACE,8BAAA;AAFF;;AAKA;EACE,mCC3CU;ED4CV,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,+BAAA;AAFF;;AAKA;EACE,0BAAA;AAFF;;AAKA;EACE,mBAAA;AAFF;;AAKA;EACE,4BAAA;AAFF","sourcesContent":["@use \"../../../../../../../assets/scss/colors\";\n@use \"../../../../../../../assets/scss/typography\";\n\n.pix-receipt-content-mobile {\n  width: 100%;\n  height: 100%;\n  background: #ffffff;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);\n  border-radius: 16px;\n}\n\n.pix-receipt-capture-mobile {\n  padding: 10%;\n}\n\n.pix-receipt-table-mobile {\n  width: 100%;\n  display: inline-flex;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid #dadada;\n  padding-top: 16px;\n  padding-bottom: 16px;\n}\n\n.pix-receipt-mobile {\n  display: flex;\n  flex-direction: column;\n  margin-top: 24px;\n}\n\n.pix-receipt-h1-mobile {\n  font-family: typography.$font-main;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 120%;\n  color: #737373;\n}\n\n.border-bottom-none {\n  border-bottom: none !important;\n}\n\n.pix-receipt-title-mobile {\n  font-family: typography.$font-bold;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 32px;\n  line-height: 125%;\n  color: var(--k4n-primary-color);\n}\n\n.mg-top-8 {\n  margin-top: 8px !important;\n}\n\n.mg-bottom-50 {\n  margin-bottom: 50px;\n}\n\n.text-align-right {\n  text-align: right !important;\n}\n","$font-main: \"K4n-Regular\", sans-serif;\n\n$font-bold: \"K4n-Bold\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
