// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-navigation {
  float: right;
  width: 104px;
  height: 24px;
  display: inline-flex;
}

.navigation-item-active {
  width: 24px;
  height: 24px;
  background: var(--k4n-secondary-color);
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
}

.navigation-item {
  width: 24px;
  height: 24px;
  background: transparent;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
}

.navigation-item-txt-active {
  font-family: "K4n-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: var(--k4n-primary-color);
}

.navigation-item-txt {
  font-family: "K4n-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: var(--k4n-primary-color);
}

.wd-138 {
  width: 138px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/features/StepNavigation/styles.scss","webpack://./src/assets/scss/_typography.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,oBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,sCAAA;EACA,4CAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,uBAAA;EACA,4CAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AAFF;;AAKA;EACE,mCC7BU;ED8BV,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,+BAAA;AAFF;;AAKA;EACE,sCCzCU;ED0CV,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,+BAAA;AAFF;;AAKA;EACE,uBAAA;AAFF","sourcesContent":["@use \"../../../assets/scss/colors\";\n@use \"../../../assets/scss/typography\";\n\n.step-navigation {\n  float: right;\n  width: 104px;\n  height: 24px;\n  display: inline-flex;\n}\n\n.navigation-item-active {\n  width: 24px;\n  height: 24px;\n  background: var(--k4n-secondary-color);\n  border: 1px solid var(--k4n-secondary-color);\n  border-radius: 50%;\n  margin: 0 auto;\n  cursor: pointer;\n}\n\n.navigation-item {\n  width: 24px;\n  height: 24px;\n  background: transparent;\n  border: 1px solid var(--k4n-secondary-color);\n  border-radius: 50%;\n  margin: 0 auto;\n  cursor: pointer;\n}\n\n.navigation-item-txt-active {\n  font-family: typography.$font-bold;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 140%;\n  text-align: center;\n  color: var(--k4n-primary-color);\n}\n\n.navigation-item-txt {\n  font-family: typography.$font-main;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 140%;\n  text-align: center;\n  color: var(--k4n-primary-color);\n}\n\n.wd-138 {\n  width: 138px !important;\n}\n","$font-main: \"K4n-Regular\", sans-serif;\n\n$font-bold: \"K4n-Bold\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
