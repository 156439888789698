// import { any } from "cypress/types/bluebird"
import { useContext, useEffect, useState } from "react"
import { OnboardingContext } from "../../../../context/OnboardingContext"
import services from "../../../../services/svcmesh"
import OnboardingModal from "../../../features/OnboardingModal"
import "./photo.scss"

const PhotoCam = () => {
  const { goBack, savePhase1 } = useContext(OnboardingContext)

  const [failCount, setFailCount] = useState(0)
  const [error, setError] = useState(``)
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)

  const Saffe = (window as any).SAFFE
  useEffect(() => {
    if (!showError) {
      const el = document.getElementById(`saffeCaptureComponent`)
      if (Saffe && el) {
        setError(``)
        console.log(Saffe)
        // Saffe.options.lang = `pt-br`

        if (window.Cypress) {
          // we are running in Cypress
          // so do something different here
          Saffe.init.oneStepCapture(
            {
              // Defina as cores primária e secundária do componente;
              primaryColor: `var(--k4n-primary-color)`,
              secondaryColor: `var(--k4n-secondary-color)`,
              lang: `pt-br`,
              timeout: 500,
            },
            // Aponte a função de callback que será executado no final da captura,
            // tendo como parametros (image, error);
            captureCallback,
          )
        } else {
          // we are running in a regular ol' browser
          Saffe.init.oneStepCapture(
            {
              // Defina as cores primária e secundária do componente;
              primaryColor: `var(--k4n-primary-color)`,
              secondaryColor: `var(--k4n-secondary-color)`,
              lang: `pt-br`,
              timeout: 50000,
            },
            // Aponte a função de callback que será executado no final da captura,
            // tendo como parametros (image, error);
            captureCallback,
          )
        }
        // Para interromper a captura use o metodo correspondente ao iniciado;
        // EX: window.SAFFE.stop.oneStepCapture(), window.SAFFE.stop.twoStepsCapture(),
        // window.SAFFE.stop.twoStepsBlinkCapture(), ... ; threeStepsBlinkCapture
      }
    }
  }, [Saffe, showError])

  const captureCallback = async (images: any, error: any) => {
    setError(``)
    setLoading(true)
    if (error) {
      console.log(`ERROR`)
      console.log(JSON.stringify(error))
      setFailCount(failCount + 1)
      // setError(error)
      setError(`Não foi possível te identificar, tente novamente`)
      setShowError(true)
    } else {
      try {
        console.log(`callback`)
        //console.log(image.image);

        const res = await services.faceIdService.getLiveness(images.image)
        const liveness = res.data
        console.log(liveness)

        if (liveness.status !== 200 || !liveness.liveness) {
          setFailCount(failCount + 1)
          setError(
            `Não foi possível identificar seu rosto. Tentativa ${
              failCount + 1
            } de 3.`,
          )
          setShowError(true)
        } else if (liveness.liveness.status !== 200) {
          setFailCount(failCount + 1)
          setError(
            `Não foi possível identificar seu rosto. Tentativa ${
              failCount + 1
            } de 3.`,
          )
          setShowError(true)
        } else {
          await savePhase1(liveness)
        }
      } catch (error) {
        console.log(error)
        setFailCount(failCount + 1)
        await savePhase1({})
      }
    }
    setLoading(false)
  }

  return (
    <div className="cam-container">
      <div className="w-100">
        <button onClick={goBack} className="onboarding-close-btn" />
      </div>

      {loading && (
        <div style={{ position: `relative`, textAlign: `center` }}>
          Validando...
        </div>
      )}
      <div id="saffeCaptureComponent" className="bg-green"></div>

      <OnboardingModal isOpen={showError} onClose={() => setShowError(false)}>
        <div>
          <div className="modal-title mb-2">Houve um erro na captura</div>
          <div className="modal-message">
            {failCount >= 3
              ? `Sem problemas! Vamos validar isso mais tarde`
              : error}
          </div>
          <div className="flex-end mt-3">
            <button
              className="onboarding-btn-primary"
              onClick={() => {
                if (failCount >= 3) savePhase1(`erro`)
                else setShowError(false)
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </OnboardingModal>
    </div>
  )
}

export default PhotoCam
