// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --k4n-primary-color: #005476; /* backgrounds and some texts */
    --k4n-secondary-color: #fbb040; /* backgrounds and some texts */
    --k4n-primary-light-color: #095F82; /* inputs and buttons texts */
    --k4n-secondary-light-color: #fff2de; /* nav buttons and some backgrounds */
    /* insurance */
    --k4n-tertiary-color: #004764; /* some tabs in insurance */
}

#root {
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B,EAAE,+BAA+B;IAC7D,8BAA8B,EAAE,+BAA+B;IAC/D,kCAAkC,EAAE,6BAA6B;IACjE,oCAAoC,EAAE,qCAAqC;IAC3E,cAAc;IACd,6BAA6B,EAAE,2BAA2B;AAC9D;;AAEA;IACI,YAAY;AAChB","sourcesContent":[":root {\n    --k4n-primary-color: #005476; /* backgrounds and some texts */\n    --k4n-secondary-color: #fbb040; /* backgrounds and some texts */\n    --k4n-primary-light-color: #095F82; /* inputs and buttons texts */\n    --k4n-secondary-light-color: #fff2de; /* nav buttons and some backgrounds */\n    /* insurance */\n    --k4n-tertiary-color: #004764; /* some tabs in insurance */\n}\n\n#root {\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
