import {
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react"
import LoadingOverlay from "../components/features/LoadingOverlay"
import k4n_logo from "../assets/images/kikkin_white_logo.svg"
import services from "../services/svcmesh"

type ThemeContextType = {
  logo: string
  theme: any
}

const themeContextDefaultValues = {
  logo: k4n_logo,
  theme: {
    primary_color: `#005476`,
    secondary_color: `#fbb040`,
  },
}

export const ThemeContext = createContext<ThemeContextType>(
  themeContextDefaultValues,
)

const ThemeProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [loading, setLoading] = useState(true)
  const [logo, setLogo] = useState(themeContextDefaultValues.logo)
  const [theme, setTheme] = useState(themeContextDefaultValues.theme)

  useEffect(() => {
    setLoading(true)
    const hasStored = getFromLocalStorage()
    if (!hasStored) getCustomTheme()
  }, [])

  const getCustomTheme = async () => {
    console.log(`getCustomTheme (API)`)
    try {
      const res = await services.themeService.getPartnerTheme()
      console.log(res.data)
      const theme_response = res.data

      if (theme_response.logo) {
        setLogo(theme_response.logo)
        sessionStorage.setItem(`logo`, theme_response.logo)
      }

      if (theme_response.custom_theme) {
        sessionStorage.setItem(
          `custom_theme`,
          JSON.stringify(theme_response.custom_theme),
        )
        setCssVariables(theme_response.custom_theme)
      }
    } catch (error) {
      console.log(`Theme Error => ` + error)
    }
    setLoading(false)
  }

  const getFromLocalStorage = () => {
    const custom_theme = sessionStorage.getItem(`custom_theme`)
    if (custom_theme) setCssVariables(JSON.parse(custom_theme))

    const logo = sessionStorage.getItem(`logo`)
    if (logo) setLogo(logo)

    if (logo || custom_theme) {
      console.log(`from sessionStorage`)
      setLoading(false)
      return true
    }
    return false
  }

  const setCssVariables = (partner_theme: any = {}) => {
    setTheme(partner_theme)
    const r = document.querySelector(`:root`) as any

    if (partner_theme.primary_color)
      r?.style.setProperty(`--k4n-primary-color`, partner_theme.primary_color)

    if (partner_theme.primary_light_color)
      r?.style.setProperty(
        `--k4n-primary-light-color`,
        partner_theme.primary_light_color,
      )

    if (partner_theme.secondary_color)
      r?.style.setProperty(
        `--k4n-secondary-color`,
        partner_theme.secondary_color,
      )

    if (partner_theme.secondary_light_color)
      r?.style.setProperty(
        `--k4n-secondary-light-color`,
        partner_theme.secondary_light_color,
      )

    if (partner_theme.tertiary_color)
      r?.style.setProperty(`--k4n-tertiary-color`, partner_theme.tertiary_color)
  }

  return (
    <ThemeContext.Provider value={{ logo, theme }}>
      {loading ? <LoadingOverlay noTheme={true} /> : children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
