// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pix-receipt-content {
  margin-top: 20px;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.pix-receipt-img-title {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-top: 48px;
  border-bottom: 1px solid #dadada;
}

.pix-receipt-message {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.pix-receipt-nav-img {
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;
}

.pix-receipt-img-close {
  width: 22.54px;
  height: 22.54px;
  cursor: pointer;
}

.pix-receipt-img-share {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.pix-receipt-capture {
  padding: 42px 68px;
}

.pix-receipt-content-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.mg-bottom-17 {
  margin-bottom: 17px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/Pix/PixPayment/PixReceipt/styles.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,4CAAA;EACA,mBAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,gCAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAFF;;AAKA;EACE,cAAA;EACA,eAAA;EACA,eAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,eAAA;AAFF;;AAKA;EACE,kBAAA;AAFF;;AAKA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,sBAAA;AAFF;;AAKA;EACE,8BAAA;AAFF","sourcesContent":["@use \"../../../../../../assets/scss/colors\";\n@use \"../../../../../../assets/scss/typography\";\n\n.pix-receipt-content {\n  margin-top: 20px;\n  width: 100%;\n  height: auto;\n  background: #ffffff;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);\n  border-radius: 16px;\n}\n\n.pix-receipt-img-title {\n  display: flex;\n  align-items: center;\n  padding-bottom: 20px;\n  margin-top: 48px;\n  border-bottom: 1px solid #dadada;\n}\n\n.pix-receipt-message {\n  display: flex;\n  flex-direction: column;\n  margin-top: 48px;\n}\n\n.pix-receipt-nav-img {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 44px;\n}\n\n.pix-receipt-img-close {\n  width: 22.54px;\n  height: 22.54px;\n  cursor: pointer;\n}\n\n.pix-receipt-img-share {\n  width: 30px;\n  height: 30px;\n  cursor: pointer;\n}\n\n.pix-receipt-capture {\n  padding: 42px 68px;\n}\n\n.pix-receipt-content-title {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n}\n\n.mg-bottom-17 {\n  margin-bottom: 17px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
