// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-yellow {
  color: var(--k4n-secondary-color);
}

.onboarding-cam-page {
  padding: 60px 120px;
}

.tips-text-title {
  font-size: 2rem;
  line-height: 2.3rem;
}

@media screen and (max-width: 768px) {
  .onboarding-cam-page {
    padding: 40px 35px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Onboarding/identity/identity.scss"],"names":[],"mappings":"AAGA;EACE,iCAAA;AAFF;;AAKA;EACE,mBAAA;AAFF;;AAKA;EACE,eAAA;EACA,mBAAA;AAFF;;AAKA;EACE;IACE,kBAAA;EAFF;AACF","sourcesContent":["@use \"../../../../assets/scss/colors\";\n@use \"../../../../assets/scss/typography\";\n\n.text-yellow {\n  color: var(--k4n-secondary-color);\n}\n\n.onboarding-cam-page {\n  padding: 60px 120px;\n}\n\n.tips-text-title {\n  font-size: 2rem;\n  line-height: 2.3rem;\n}\n\n@media screen and (max-width: 768px) {\n  .onboarding-cam-page {\n    padding: 40px 35px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
