import "./styles.scss"
import couple from "../../../assets/images/happy-couple.png"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import { OnboardingContext } from "../../../context/OnboardingContext"

const Finish = () => {
  const navigate = useNavigate()

  const { completeOnboarding } = useContext(OnboardingContext)

  const handleGoTo = () => {
    completeOnboarding()
    navigate(`/`)
  }

  return (
    <div className="gray-bg">
      <div
        className="corner-image-bg"
        style={{ backgroundImage: `url(${couple})` }}
      >
        <div className="dark-overlay">
          <div className="start-text-container ml-60px h-100 d-flex flex-column flex-justify-content-center">
            <div className="start-text-title mt-5 mb-3">
              Viu? Foi rápido!
              <br /> Sua conta será aberta em até 48 horas.
            </div>
            <div className="mb-3 sms-text-finish">
              Você receberá um SMS com uma senha temporária para acessar sua
              conta
            </div>
            <button className="start-button mb-3" onClick={handleGoTo}>
              Acessar meu Kikkin
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Finish
