import { useKeycloak } from "@react-keycloak/web"
import { KeycloakTokenParsed } from "keycloak-js"
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { CustomModal } from "k4n-components"
import LoadingOverlay from "../components/features/LoadingOverlay"
import { ThemeContext } from "./ThemeContext"
import Timer from "../assets/images/navbar-banking/timer.svg"
import {
  Account,
  ClientePf,
  CreateSrpDigitalSignatureDto,
} from "k4n-svcmesh-sdk"
import services from "../services/svcmesh"

const IDLE_TIME = 60 * 5 // seconds
export const SHOW_TIMER_TIME = 30 // seconds
const IDLE_WARNING_TIME = 15 // seconds

type AuthContextType = {
  user: ClientePf | null
  loading: boolean
  current_account: Account | null
  emailVerified: boolean
  hasDigitalSignature: boolean
  digitalSignature: CreateSrpDigitalSignatureDto
  login: (cpf?: string) => void
  firstLogin: (cpf?: string) => void
  logout: () => void
  refreshUser: () => void
  openResetPassPage: () => void
  openAccountManagement: () => void
  addHasDigitalSignature: (data: boolean) => void
  addDigitalSignature: (data: CreateSrpDigitalSignatureDto) => void
  remaningTime: number
}

const contextDefaultValues: AuthContextType = {
  user: null,
  loading: false,
  current_account: null,
  emailVerified: false,
  hasDigitalSignature: false,
  digitalSignature: {},
  login: () => {},
  firstLogin: () => {},
  logout: () => {},
  refreshUser: () => {},
  openResetPassPage: () => {},
  openAccountManagement: () => {},
  addHasDigitalSignature: () => {},
  addDigitalSignature: () => {},
  remaningTime: IDLE_TIME,
}

export const AuthContext = createContext<AuthContextType>(contextDefaultValues)

const AuthProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const { initialized, keycloak } = useKeycloak()
  const { theme } = useContext(ThemeContext)

  const [user, setUser] = useState<ClientePf | null>(contextDefaultValues.user)
  const [current_account, setCurrentAccount] = useState<Account | null>(
    contextDefaultValues.current_account,
  )
  const [loading, setLoading] = useState(contextDefaultValues.loading)
  const [emailVerified, setEmailVerified] = useState(
    contextDefaultValues.emailVerified,
  )
  const [hasDigitalSignature, setHasDigitalSignature] = useState(
    contextDefaultValues.hasDigitalSignature,
  )
  const [digitalSignature, setDigitalSignature] = useState(
    contextDefaultValues.digitalSignature,
  )

  // start idle timer variables
  const [idleWarning, setIdleWarning] = useState(false)
  const [remaningTime, setRemaningTime] = useState(
    contextDefaultValues.remaningTime,
  )
  const idleTimer = useRef(setTimeout(() => {}, 1000))
  // end idle timer variables

  const addHasDigitalSignature = (data: boolean) => setHasDigitalSignature(data)
  const addDigitalSignature = (data: CreateSrpDigitalSignatureDto) =>
    setDigitalSignature(data)

  useEffect(() => {
    if (initialized) {
      console.log(keycloak)
      if (keycloak.idTokenParsed && keycloak.authenticated) {
        console.log(`Handle success login... `)
        handleSuccessLogin(keycloak.idTokenParsed)
      }
      if (keycloak.idToken)
        services.authService.setBearerToken(keycloak.idToken)
    }
  }, [initialized, keycloak])

  useEffect(() => checkIntegrity(), [user, keycloak])

  useEffect(() => {
    if (user) {
      renewIdleTimer()
      document.addEventListener(`pointermove`, renewIdleTimer)
      return () => document.removeEventListener(`pointermove`, renewIdleTimer)
    } else {
      clearTimeout(idleTimer.current)
      document.removeEventListener(`pointermove`, renewIdleTimer)
    }
  }, [user])

  const login = (cpf = ``) => {
    if (!keycloak.authenticated) {
      cpf = cpf.replaceAll(`.`, ``).replace(`-`, ``)
      let url = keycloak.createLoginUrl({
        loginHint: cpf,
        locale: `pt-BR`,
      })
      url += `&theme=primary_${String(theme.primary_color).replace(
        `#`,
        ``,
      )},secondary_${String(theme.secondary_color).replace(`#`, ``)}`
      window.location.href = url
    }
  }

  const firstLogin = (cpf = ``) => {
    if (!keycloak.authenticated) {
      cpf = cpf.replaceAll(`.`, ``).replace(`-`, ``)
      let url = keycloak.createLoginUrl({
        loginHint: cpf,
        locale: `pt-BR`,
      })
      url += `&theme=primary_${String(theme.primary_color).replace(
        `#`,
        ``,
      )},secondary_${String(theme.secondary_color).replace(`#`, ``)}`
      url += `&first=true`
      window.location.href = url
    }
  }

  const logout = async () => {
    setLoading(true)
    try {
      await keycloak.logout()
      setUser(null)
      setCurrentAccount(null)
      services.authService.clearBearerToken()
    } catch (error) {
      window.alert(error)
    }
    setLoading(false)
  }

  const refreshUser = async () => {
    setLoading(true)
    await keycloak.updateToken(50)
    if (keycloak.idTokenParsed) handleSuccessLogin(keycloak.idTokenParsed)
  }

  const openResetPassPage = () => {
    let url = keycloak.createLoginUrl({
      loginHint: user?.cpf,
      locale: `pt-BR`,
      action: `UPDATE_PASSWORD`,
    })
    url += `&theme=primary_${String(theme.primary_color).replace(
      `#`,
      ``,
    )},secondary_${String(theme.secondary_color).replace(`#`, ``)}`
    window.location.href = url
  }

  const openAccountManagement = () => {
    keycloak.accountManagement()
  }

  const handleSuccessLogin = async (user: KeycloakTokenParsed) => {
    setLoading(true)
    try {
      if (user && user.sub) {
        console.log(user)
        const u = await services.bankingService.getUserByOidcId(user.sub)
        console.log(
          `🚀 ~ file: AuthProvider.tsx ~ line 71 ~ handleSuccessLogin ~ u`,
          u,
        )
        setUser(u.data)
        setHasDigitalSignature(u.data?.hasDigitalSignature)
        setEmailVerified(user.email_verified || false)
        // Always pick first account from user...
        // in the future if user has multiple accounts, refactor
        setCurrentAccount(u.data?.accounts[0])
      }
    } catch (error) {
      window.alert(error)
    }
    setLoading(false)
  }

  const checkIntegrity = () => {
    if (user && keycloak.idTokenParsed && user.emails && user.emails.length) {
      if (user.emails[0].email !== keycloak.idTokenParsed.email) {
        // TODO: send warning to server or fix the mistake
        console.error(`Integrity error with keycloak (emails do not match) `)
      }
    }
  }

  const renewIdleTimer = () => {
    clearTimeout(idleTimer.current)
    setRemaningTime(IDLE_TIME)
    setIdleWarning(false)
    idleTimer.current = setTimeout(() => {
      // First step: count idle time in background
      // then show the navbar COUNTDOWN
      setRemaningTime(SHOW_TIMER_TIME)
      idleTimer.current = setTimeout(() => {
        // Second step: count time showing the countdown
        // then show WARNING
        setRemaningTime(IDLE_WARNING_TIME)
        setIdleWarning(true)
        idleTimer.current = setTimeout(() => {
          // Third step: count time showing the warning
          // then LOGOUT
          setRemaningTime(0)
          logout()
        }, IDLE_WARNING_TIME * 1000)
      }, (SHOW_TIMER_TIME - IDLE_WARNING_TIME) * 1000)
    }, (IDLE_TIME - SHOW_TIMER_TIME) * 1000)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        current_account,
        emailVerified,
        hasDigitalSignature,
        digitalSignature,
        login,
        firstLogin,
        logout,
        refreshUser,
        openAccountManagement,
        openResetPassPage,
        addHasDigitalSignature,
        addDigitalSignature,
        remaningTime,
      }}
    >
      {loading ? <LoadingOverlay /> : children}
      <CustomModal
        isOpen={!!user && idleWarning}
        onClose={() => {
          renewIdleTimer()
          console.log(`close idle warning`)
        }}
        isBanking
      >
        <div className="text-center pb-3">
          <img src={Timer} width="60px" />
          <h1 className="mt-3">Você ainda está aí?</h1>
          <div>
            Para sua segurança você será desconectado em {remaningTime} segundos
            {/* <span
              style={{
                width: `1.8rem`,
                display: `inline-block`,
              }}
            >
              {remaningTime < 10 ? `0` + remaningTime : remaningTime}
            </span> */}
          </div>
        </div>
      </CustomModal>
    </AuthContext.Provider>
  )
}

export default AuthProvider
