// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wait-account-container {
  background-color: var(--k4n-primary-color);
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding-top: 100px;
}

.wait-account-h1 {
  font-family: "K4n-Regular", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  color: var(--k4n-secondary-color);
}

.wait-account-title {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  max-width: 742px;
  margin-bottom: 60px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/WaitAccountOpen/styles.scss","webpack://./src/assets/scss/_typography.scss"],"names":[],"mappings":"AAEA;EACE,0CAAA;EACA,WAAA;EACA,iBAAA;EACA,SAAA;EACA,kBAAA;AADF;;AAIA;EACE,sCCXU;EDYV,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,iCAAA;AADF;;AAIA;EACE,cAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADF","sourcesContent":["@use \"../../../assets/scss/typography\";\n\n.wait-account-container {\n  background-color: var(--k4n-primary-color);\n  width: 100%;\n  min-height: 100vh;\n  margin: 0;\n  padding-top: 100px;\n}\n\n.wait-account-h1 {\n  font-family: typography.$font-main;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 48px;\n  line-height: 120%;\n  text-align: center;\n  color: var(--k4n-secondary-color);\n}\n\n.wait-account-title {\n  margin: 0 auto;\n  width: 100%;\n  text-align: center;\n  max-width: 742px;\n  margin-bottom: 60px;\n}\n","$font-main: \"K4n-Regular\", sans-serif;\n\n$font-bold: \"K4n-Bold\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
