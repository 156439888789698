// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 312px;
  height: 70px;
  background: var(--k4n-primary-light-color);
  border-radius: 28px;
  border: none;
  padding-left: 28px;
  padding-right: 28px;
  margin-bottom: 18px;
  color: var(--k4n-primary-color);
  cursor: text;
}
.password-input-container input {
  border: none;
  padding: 0;
  margin: 0;
  flex: 1 1;
  background-color: transparent;
  color: var(--k4n-primary-color);
}

.password-input-container input::placeholder {
  color: #fff;
  opacity: 0.65;
}

.password-input-button {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/features/NumericPasswordInput/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,0CAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,+BAAA;EACA,YAAA;AACF;AAAE;EACE,YAAA;EACA,UAAA;EACA,SAAA;EACA,SAAA;EACA,6BAAA;EACA,+BAAA;AAEJ;;AAEA;EACE,WAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".password-input-container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 312px;\n  height: 70px;\n  background: var(--k4n-primary-light-color);\n  border-radius: 28px;\n  border: none;\n  padding-left: 28px;\n  padding-right: 28px;\n  margin-bottom: 18px;\n  color: var(--k4n-primary-color);\n  cursor: text;\n  input {\n    border: none;\n    padding: 0;\n    margin: 0;\n    flex: 1;\n    background-color: transparent;\n    color: var(--k4n-primary-color);\n  }\n}\n\n.password-input-container input::placeholder {\n  color: #fff;\n  opacity: 0.65;\n}\n\n.password-input-button {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
