// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.extrato-header {
  width: 100%;
  background: var(--k4n-secondary-light-color);
  border-radius: 16px;
  height: 77px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  justify-content: space-between;
  margin: 56px 0 20px 0;
}

.extrato-title {
  font-family: "K4n-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--k4n-primary-light-color);
  display: inline-block;
}

.extrato-wrapper {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-block;
  padding: 0 75px;
}

.extrato-wrapper-mobile {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/extrato/styles.scss","webpack://./src/assets/scss/_typography.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,4CAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,qBAAA;AAFF;;AAKA;EACE,mCCdU;EDeV,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,qCAAA;EACA,qBAAA;AAFF;;AAKA;EACE,6BAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,qBAAA;EACA,eAAA;AAFF;;AAKA;EACE,6BAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,qBAAA;AAFF","sourcesContent":["@use \"../../../../assets/scss/colors\";\n@use \"../../../../assets/scss/typography\";\n\n.extrato-header {\n  width: 100%;\n  background: var(--k4n-secondary-light-color);\n  border-radius: 16px;\n  height: 77px;\n  display: flex;\n  align-items: center;\n  padding: 0 32px;\n  justify-content: space-between;\n  margin: 56px 0 20px 0;\n}\n\n.extrato-title {\n  font-family: typography.$font-bold;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 24px;\n  color: var(--k4n-primary-light-color);\n  display: inline-block;\n}\n\n.extrato-wrapper {\n  background-color: transparent;\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  display: inline-block;\n  padding: 0 75px;\n}\n\n.extrato-wrapper-mobile {\n  background-color: transparent;\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  display: inline-block;\n}\n","$font-main: \"K4n-Regular\", sans-serif;\n\n$font-bold: \"K4n-Bold\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
