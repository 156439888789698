// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-mobile-txt {
  font-family: "K4n-Bold", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
  margin-bottom: 20px;
}

.home-mobile-content-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 0 0 10%;
}

.home-mobile-content {
  max-height: 200px !important;
  overflow-y: auto;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/Home/mobile/styles.scss","webpack://./src/assets/scss/_typography.scss"],"names":[],"mappings":"AAGA;EACE,mCCFU;EDGV,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;AAFF;;AAKA;EACE,4BAAA;EACA,gBAAA;EACA,WAAA;AAFF","sourcesContent":["@use \"../../../../../assets/scss/colors\";\n@use \"../../../../../assets/scss/typography\";\n\n.home-mobile-txt {\n  font-family: typography.$font-bold;\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 120%;\n  color: #0a0203;\n  margin-bottom: 20px;\n}\n\n.home-mobile-content-div {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 30px;\n  padding: 0 0 10%;\n}\n\n.home-mobile-content {\n  max-height: 200px !important;\n  overflow-y: auto;\n  width: 100%;\n}\n","$font-main: \"K4n-Regular\", sans-serif;\n\n$font-bold: \"K4n-Bold\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
