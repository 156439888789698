import { AxiosError } from "axios"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { PixStorageContext } from "../../../../../../context/PixStorageProvider"
import { formattedCurrency } from "../../../../../../services/banking"
import { Step3PixPayment } from "../../../../../../typings/step3-pix-payment.dto"
import {
  CustomModal,
  FailureModal,
  SuccessModal,
  SecondaryButton,
  PrimaryButton,
} from "k4n-components"
import PixNav from "../../nav-pix"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import key from "../../../../../../assets/images/key.svg"
import DigitalSignature from "../../../../DigitalSignature/Form"
import { BalanceContext } from "../../../../../../context/BalanceProvider"
import services from "../../../../../../services/svcmesh"
import {
  ScheduledPixPayment,
  TipoPagamentoPix,
  TransferPixResource,
} from "k4n-svcmesh-sdk"

const PixPaymentStep3 = () => {
  const [showModal, setShowModal] = useState(false)
  const [showModalError, setShowModalError] = useState(false)
  const [errorPayment, setErrorPayment] = useState(``)
  const [btnLoading, setBtnLoading] = useState(false)
  const [time, setTime] = useState(``)
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false)
  const { refreshBalance } = useContext(BalanceContext)

  const { changePixPayment, changePixEnvironment } = useContext(
    BankingNavigationContext,
  )
  const {
    pixPaymentStep2,
    pixCopyPasteStep1,
    addPixPaymentStep3,
    pixPaymentType,
  } = useContext(PixStorageContext)
  const { user, current_account } = useContext(AuthContext)

  const handlePixPaymentChange = (newPage: number) => {
    changePixPayment(newPage)
  }

  const returnPixPanel = () => {
    changePixEnvironment(1)
    changePixPayment(1)
  }

  const onPixPayment = async () => {
    setBtnLoading(true)
    const dadosEnvioPorChave = {
      ticket: pixPaymentStep2.ticket,
      chave: pixPaymentStep2.chave,
      tipoChave: pixPaymentStep2.tipoChave,
    }
    const contaOrigem = {
      agencia: current_account?.agencia,
      agenciaDigito: current_account?.agenciaDigito,
      conta: current_account?.conta,
      contaDigito: current_account?.contaDigito,
      contaPgto: current_account?.contaPgto,
      tipoConta: current_account?.tipoConta,
    }
    const sendPayment = {
      dadosEnvioPorChave: dadosEnvioPorChave,
      valor: null,
      codigoOperacaoCliente: ``,
      descricaoCliente: ``,
      prioridade: 0,
      contaOrigem: contaOrigem,
      informacoesAdicionais: pixPaymentStep2.message,
      idConciliacaoRecebedor: ``,
      tpQRCode: null,
    } as unknown as TransferPixResource
    if (pixPaymentType === TipoPagamentoPix.PIX_COPYPASTE) {
      try {
        sendPayment.valor = pixCopyPasteStep1.valor!
        sendPayment.idConciliacaoRecebedor =
          pixCopyPasteStep1.idConciliacaoRecebedor!
        sendPayment.tpQRCode = pixCopyPasteStep1.tpQRCode!
        console.log(sendPayment)
        const res = await services.bankingService.tranferPixResource(
          sendPayment,
        )
        if (res.data.sucesso === true) {
          const dayTime = dayjs().format(`DD/MM/YYYY - HH:mm`)
          setTime(dayTime)
          const saveObj = {
            codigoTransacao: res.data.codigoTransacao,
            data: dayTime,
            valor: sendPayment.valor,
          } as Step3PixPayment
          setShowModal(true)
          addPixPaymentStep3(saveObj)
          setBtnLoading(false)
          refreshBalance()
        }
      } catch (error: any) {
        const err = error as AxiosError<any>
        setErrorPayment(err.response?.data.message)
        setBtnLoading(false)
        setShowModalError(true)
      }
    } else {
      try {
        sendPayment.valor = pixPaymentStep2.valor!
        console.log(sendPayment)
        const res = await services.bankingService.tranferPixResource(
          sendPayment,
        )
        if (res.data.sucesso === true) {
          const dayTime = dayjs().format(`DD/MM/YYYY - HH:mm`)
          setTime(dayTime)
          const saveObj = {
            codigoTransacao: res.data.codigoTransacao,
            data: dayTime,
            valor: sendPayment.valor,
          } as Step3PixPayment
          saveFavoritePix()
          setShowModal(true)
          addPixPaymentStep3(saveObj)
          setBtnLoading(false)
          refreshBalance()
        }
      } catch (error: any) {
        const err = error as AxiosError<any>
        setErrorPayment(err.response?.data.message)
        setBtnLoading(false)
        setShowModalError(true)
      }
    }
  }

  const scheduledPixPayment = async () => {
    setBtnLoading(true)
    const sendScheduledPix = {
      customer_pf: { id: user?.id },
      chave: pixPaymentStep2.chave,
      tipo_chave: pixPaymentStep2.tipoChave,
      valor: pixPaymentStep2.valor,
      prioridade: 1,
      conta_origem_agencia: current_account?.agencia,
      conta_origem_agencia_digito: current_account?.agenciaDigito || ``,
      conta_origem_conta: current_account?.conta,
      conta_origem_conta_digito: current_account?.contaDigito,
      conta_origem_conta_pgto:
        current_account?.conta! + current_account?.contaDigito!,
      conta_origem_tipo_conta: current_account?.tipoConta,
      codigo_operacao_cliente: ``,
      descricao_cliente: ``,
      informacoes_adicionais: ``,
      id_conciliacao_recebedor: ``,
      tp_qr_code: 1,
      scheduled_date: pixPaymentStep2.data_agendamento,
    } as unknown as ScheduledPixPayment
    console.log(sendScheduledPix)
    try {
      const res = await services.bankingService.scheduledPix(sendScheduledPix)
      if (res.data.sucesso === true) {
        const saveObj = {
          data: pixPaymentStep2.data_agendamento,
          valor: pixPaymentStep2.valor,
          agendamento: pixPaymentStep2.agendamento,
        } as Step3PixPayment
        saveFavoritePix()
        addPixPaymentStep3(saveObj)
        setShowModal(true)
        setBtnLoading(false)
      }
    } catch (error: any) {
      const err = error as AxiosError<any>
      setErrorPayment(err.response?.data.message)
      setBtnLoading(false)
      setShowModalError(true)
    }
  }

  const saveFavoritePix = async () => {
    if (pixPaymentStep2.save_favorite === true) {
      try {
        const res = await services.bankingService.createCustomerFavorite({
          clientePf: { id: user?.id },
          nome: pixPaymentStep2.nome,
          cpf: pixPaymentStep2.cpf,
          chave_pix: pixPaymentStep2.chave,
        })
        if (res?.sucesso) {
          console.log(res)
        }
      } catch (error) {
        const err = error as AxiosError<any>
        console.log(err.response?.data.message)
      }
    }
  }

  useEffect(function () {}, [pixPaymentStep2])

  const authorize = () => {
    setDigitalSignatureModal(false)
    if (pixPaymentStep2.agendamento === true) {
      scheduledPixPayment()
    } else {
      onPixPayment()
    }
  }

  return (
    <div className="pix-payment-step3-container">
      <CustomModal
        isOpen={digitalSignatureModal}
        onClose={() => {
          setDigitalSignatureModal(false)
        }}
        isBanking={true}
      >
        <div style={{ width: `120px`, margin: `0 auto` }}>
          <img
            src={key}
            alt="/"
            width="120"
            height="120"
            className="key-vector"
            onLoad={(e) => SVGInject(e.target)}
          />
        </div>
        <DigitalSignature handleSuccess={authorize} />
      </CustomModal>
      <PixNav step={3} title={`Pagamento`} />
      <div className="pix-payment-step3-content">
        <div className="pix-payment-step3-content-title">
          <h1 className="pix-payment-step3-title">Pagar</h1>
        </div>
        <div className="pix-payment-step3-content-table">
          <span className="pix-payment-step3-txt-bold">Valor a pagar</span>
          <span className="pix-payment-step3-txt-value">
            {formattedCurrency(pixPaymentStep2.valor!)}
          </span>
          <span className="pix-payment-step3-txt-bold">Agência e Conta</span>
          <span className="pix-payment-step3-txt">
            Ag: {current_account?.agencia} Cc: {current_account?.conta}
          </span>
          <span className="pix-payment-step3-txt-bold">Data do pagamento</span>
          <span className="pix-payment-step3-txt">
            {dayjs().format(`DD/MM/YYYY`)}
          </span>
        </div>
        <div className="pix-payment-step3-content-table">
          <div className="row align-items-start">
            <div className="col direction-column">
              <span className="pix-payment-step3-txt-bold">Para</span>
              <span className="pix-payment-step3-txt">
                {pixPaymentStep2.nome}
              </span>
            </div>
            <div className="col direction-column">
              <span className="pix-payment-step3-txt-bold">Chave</span>
              <span className="pix-payment-step3-txt">
                {pixPaymentStep2.chave}
              </span>
            </div>
            {pixPaymentStep2.cpf === ` ` ? (
              <div></div>
            ) : (
              <div className="col direction-column">
                <span className="pix-payment-step3-txt-bold">CPF</span>
                <span className="pix-payment-step3-txt">
                  {pixPaymentStep2.cpf}
                </span>
              </div>
            )}
          </div>
          <span className="pix-payment-step3-txt-bold">Agência e Conta</span>
          <span className="pix-payment-step3-txt">
            Ag: {pixPaymentStep2.agencia} Cc:{pixPaymentStep2.conta}
          </span>
        </div>
        <div className="pix-align-btn">
          <SecondaryButton
            width={`170px`}
            height={`60px`}
            text={`Voltar`}
            margin_right={`50px`}
            OnClick={() => changePixPayment(2)}
          />
          <PrimaryButton
            width={`264px`}
            height={`60px`}
            onClick={() => setDigitalSignatureModal(true)}
            disabled={btnLoading}
          >
            {!btnLoading ? (
              `Confirmar`
            ) : (
              <div className="spinner-border spinner-pix" role="status"></div>
            )}
          </PrimaryButton>
        </div>
        <FailureModal
          isOpen={showModalError}
          width={`937px`}
          height={`576px`}
          onClose={() => setShowModalError(false)}
        >
          <h1 className="pix-modal-sucess-h1">Ocorreu um Erro</h1>
          <h1 className="pix-modal-sucess-3p">• • •</h1>
          <h1 className="pix-modal-sucess-txt">{errorPayment}</h1>
          <div className="pix-align-center">
            <PrimaryButton
              width={`312px`}
              height={`60px`}
              onClick={() => setShowModalError(false)}
            >
              OK!
            </PrimaryButton>
          </div>
        </FailureModal>
        <SuccessModal
          isOpen={showModal}
          width={`937px`}
          height={`576px`}
          onClose={() => returnPixPanel()}
        >
          {pixPaymentStep2.agendamento === true ? (
            <>
              <h1 className="pix-modal-sucess-h1">
                Pix agendado com <br /> sucesso!
              </h1>
              <h1 className="pix-modal-sucess-3p">• • •</h1>
              <h1 className="pix-modal-sucess-txt">
                Tudo certo! Agendamos o pagamento de
                <br />
                <b>{formattedCurrency(pixPaymentStep2.valor)}</b>
                {` `}
                para <b>{pixPaymentStep2.nome}</b>.
              </h1>
              <h1 className="pix-modal-sucess-txt">{time}</h1>
              <div className="pix-align-center">
                <PrimaryButton
                  width={`312px`}
                  height={`60px`}
                  onClick={() => handlePixPaymentChange(4)}
                >
                  Ver comprovante
                </PrimaryButton>
              </div>
            </>
          ) : (
            <>
              <h1 className="pix-modal-sucess-h1">
                Pix realizado com <br /> sucesso!
              </h1>
              <h1 className="pix-modal-sucess-3p">• • •</h1>
              <h1 className="pix-modal-sucess-txt">
                Tudo certo! Enviamos
                {` `}
                <b>{formattedCurrency(pixPaymentStep2.valor)}</b>
                <br />
                para <b>{pixPaymentStep2.nome}</b> agora mesmo.
              </h1>
              <h1 className="pix-modal-sucess-txt">
                {` `}
                {time}
              </h1>
              <div className="pix-align-center">
                <PrimaryButton
                  width={`312px`}
                  height={`60px`}
                  onClick={() => handlePixPaymentChange(4)}
                >
                  Ver comprovante
                </PrimaryButton>
              </div>
            </>
          )}
        </SuccessModal>
      </div>
    </div>
  )
}
export default PixPaymentStep3
