// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pix-payment-wrapper-mobile {
  width: 100%;
  height: 100%;
  background-color: var(--k4n-primary-color);
  padding-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/Pix/PixPayment/styles.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,YAAA;EACA,0CAAA;EACA,iBAAA;AAFF","sourcesContent":["@use \"../../../../../assets/scss/colors\";\n@use \"../../../../../assets/scss/typography\";\n\n.pix-payment-wrapper-mobile {\n  width: 100%;\n  height: 100%;\n  background-color: var(--k4n-primary-color);\n  padding-top: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
