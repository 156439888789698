import { ReactKeycloakProvider } from "@react-keycloak/web"
import { _kc } from "k4n-svcmesh-sdk"
import AuthProvider from "../context/AuthProvider"
import BalanceProvider from "../context/BalanceProvider"
import BankingNavigationProvider from "../context/BankingNavigationContext"
import BoletoProvider from "../context/BoletoContext"
import InsuranceProvider from "../context/InsuranceContext"
import PixStorageProvider from "../context/PixStorageProvider"
import RegisterNavigationProvider from "../context/RegisterNavigationContext"
import ThemeProvider from "../context/ThemeContext"
import TransferProvider from "../context/TransferContext"
import RootRoutes from "../routes"
import LoadingOverlay from "./features/LoadingOverlay"

const App = () => {
  return (
    <ThemeProvider>
      <ReactKeycloakProvider
        authClient={_kc}
        LoadingComponent={<LoadingOverlay />}
      >
        <AuthProvider>
          <BankingNavigationProvider>
            <BalanceProvider>
              <PixStorageProvider>
                <BoletoProvider>
                  <InsuranceProvider>
                    <RegisterNavigationProvider>
                      <TransferProvider>
                        <RootRoutes />
                      </TransferProvider>
                    </RegisterNavigationProvider>
                  </InsuranceProvider>
                </BoletoProvider>
              </PixStorageProvider>
            </BalanceProvider>
          </BankingNavigationProvider>
        </AuthProvider>
      </ReactKeycloakProvider>
    </ThemeProvider>
  )
}

export default App
