import "../styles.scss"
import { ChangeEvent, useContext, useState } from "react"
import { OnboardingContext } from "../../../../context/OnboardingContext"
import man from "../../../../assets/images/man-using-smartphone.png"

const IdentityLanding = () => {
  const { goBack, startIdentity, continueIdentity } =
    useContext(OnboardingContext)

  const [loading, setLoading] = useState(false)

  const handleSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const files = e.target.files
    if (files && files[0]) {
      const p = files[0]
      const res = await toBase64(p)
      continueIdentity(res)
    }
    setLoading(false)
  }

  const toBase64 = async (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  return (
    <div className="gray-bg">
      <div
        className="corner-image-bg"
        style={{ backgroundImage: `url(${man})` }}
      >
        <div className="dark-overlay">
          <div className="start-text-container h-100 d-flex flex-column flex-justify-content-center">
            <button
              className="onboarding-go-back-white mt-60px position-absolute top-0 "
              onClick={goBack}
            />
            <div className="start-text-title mt-md-5 mt-5 mb-3">
              Vamos validar seus dados através de um documento com foto.
            </div>
            <div className="start-text-message mb-4">
              Pode ser RG, CNH, RNE, passaporte, carteira de trabalho ou
              reservista.
            </div>
            <div className="start-text-message mb-4">
              <span
                className="onboarding-btn-link"
                onClick={() =>
                  !loading && document.getElementById(`file-input`)?.click()
                }
              >
                Clique para enviar
              </span>
              se você já tiver essas imagens.
            </div>

            <input
              onChange={handleSelect}
              id="file-input"
              accept="image/*"
              type="file"
              className="d-none"
            />

            <button
              className="start-button mb-3"
              onClick={() => startIdentity()}
            >
              Tudo pronto!
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IdentityLanding
