// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boleto-wrapper {
  background-color: transparent;
  width: 100%;
  margin: 0;
  display: inline-block;
  padding-left: 75px;
  padding-right: 75px;
  margin-bottom: 25px;
}

.boleto-wrapper-mobile {
  background-color: transparent;
  width: 100%;
  margin: 0;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/boleto/styles.scss"],"names":[],"mappings":"AAGA;EACE,6BAAA;EACA,WAAA;EACA,SAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,6BAAA;EACA,WAAA;EACA,SAAA;EACA,qBAAA;AAFF","sourcesContent":["@use \"../../../../assets/scss/colors\";\n@use \"../../../../assets/scss/typography\";\n\n.boleto-wrapper {\n  background-color: transparent;\n  width: 100%;\n  margin: 0;\n  display: inline-block;\n  padding-left: 75px;\n  padding-right: 75px;\n  margin-bottom: 25px;\n}\n\n.boleto-wrapper-mobile {\n  background-color: transparent;\n  width: 100%;\n  margin: 0;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
