// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mg-top-67 {
  margin-top: 67px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/Pix/PixRegister/Mobile/styles.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;AAFF","sourcesContent":["@use \"../../../../../../assets/scss/colors\";\n@use \"../../../../../../assets/scss/typography\";\n\n.mg-top-67 {\n  margin-top: 67px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
