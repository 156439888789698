// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.address-input-label {
  color: var(--k4n-secondary-color);
  font-family: "K4n-Bold", sans-serif;
  font-size: 0.9rem;
}

.address-input {
  display: block;
  color: var(--k4n-primary-light-color);
  font-family: "K4n-Regular", sans-serif;
  caret-color: var(--k4n-primary-light-color);
  border: none;
  border-radius: 28px;
  background-color: var(--k4n-secondary-color);
  font-size: 1rem;
  outline: none;
  padding: 10px 20px;
  width: 100%;
  margin: 8px 0 16px;
}

.address-input:disabled {
  background-color: var(rgba(212, 212, 212, 0.2196078431));
  /* padding: 10px 0; */
}

.address-input-disabled-bg {
  background-color: rgba(212, 212, 212, 0.2196078431);
}

@media screen and (max-width: 768px) {
  .address-input {
    background-color: var(--k4n-primary-light-color);
  }
  .address-input:disabled {
    background-color: var(rgba(212, 212, 212, 0.2196078431));
    /* padding: 10px 0; */
  }
  .address-input-disabled-bg {
    background-color: rgba(212, 212, 212, 0.2196078431);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Onboarding/address/address.scss","webpack://./src/assets/scss/_typography.scss"],"names":[],"mappings":"AAGA,gCAAA;AACA;;EAEE,wBAAA;EACA,SAAA;AAFF;;AAKA,YAAA;AACA;EACE,0BAAA;AAFF;;AAKA;EACE,iCAAA;EACA,mCCfU;EDgBV,iBAAA;AAFF;;AAKA;EACE,cAAA;EACA,qCAAA;EACA,sCCxBU;EDyBV,2CAAA;EACA,YAAA;EACA,mBAAA;EACA,4CAAA;EACA,eAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;AAFF;;AAKA;EACE,wDAAA;EACA,qBAAA;AAFF;;AAKA;EACE,mDAAA;AAFF;;AAKA;EACE;IACE,gDAAA;EAFF;EAIA;IACE,wDAAA;IACA,qBAAA;EAFF;EAIA;IACE,mDAAA;EAFF;AACF","sourcesContent":["@use \"../../../../assets/scss/colors\";\n@use \"../../../../assets/scss/typography\";\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type=\"number\"] {\n  -moz-appearance: textfield;\n}\n\n.address-input-label {\n  color: var(--k4n-secondary-color);\n  font-family: typography.$font-bold;\n  font-size: 0.9rem;\n}\n\n.address-input {\n  display: block;\n  color: var(--k4n-primary-light-color);\n  font-family: typography.$font-main;\n  caret-color: var(--k4n-primary-light-color);\n  border: none;\n  border-radius: 28px;\n  background-color: var(--k4n-secondary-color);\n  font-size: 1rem;\n  outline: none;\n  padding: 10px 20px;\n  width: 100%;\n  margin: 8px 0 16px;\n}\n\n.address-input:disabled {\n  background-color: var(#d4d4d438);\n  /* padding: 10px 0; */\n}\n\n.address-input-disabled-bg {\n  background-color: #d4d4d438;\n}\n\n@media screen and (max-width: 768px) {\n  .address-input {\n    background-color: var(--k4n-primary-light-color);\n  }\n  .address-input:disabled {\n    background-color: var(#d4d4d438);\n    /* padding: 10px 0; */\n  }\n  .address-input-disabled-bg {\n    background-color: #d4d4d438;\n  }\n}\n","$font-main: \"K4n-Regular\", sans-serif;\n\n$font-bold: \"K4n-Bold\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
