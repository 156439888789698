// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  color: #ffffff;
  font-family: "K4n-Regular", sans-serif;
  height: 100%;
}

.banking-wrapper {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: #e5e5e5;
  flex-direction: row;
  overflow-y: scroll;
}

.loading-gradient {
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeded 8%, #f7f6f6 38%, #eeeded 54%);
  background-size: 1000px 640px;
  position: relative;
  border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/styles.scss","webpack://./src/assets/scss/_typography.scss"],"names":[],"mappings":"AAGA;;EAEE,cAAA;EACA,sCCNU;EDOV,YAAA;AAFF;;AAKA;EACE,SAAA;EACA,aAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,wBAAA;EACA,6BAAA;EACA,mCAAA;EACA,kCAAA;EACA,iCAAA;EACA,mBAAA;EACA,2EAAA;EACA,6BAAA;EACA,kBAAA;EACA,mBAAA;AAFF","sourcesContent":["@use \"../../../assets/scss/colors\";\n@use \"../../../assets/scss/typography\";\n\nhtml,\nbody {\n  color: #ffffff;\n  font-family: typography.$font-main;\n  height: 100%;\n}\n\n.banking-wrapper {\n  margin: 0;\n  height: 100vh;\n  width: 100vw;\n  background-color: #e5e5e5;\n  flex-direction: row;\n  overflow-y: scroll;\n}\n\n.loading-gradient {\n  animation-duration: 2.5s;\n  animation-fill-mode: forwards;\n  animation-iteration-count: infinite;\n  animation-name: placeHolderShimmer;\n  animation-timing-function: linear;\n  background: #f6f7f8;\n  background: linear-gradient(to right, #eeeded 8%, #f7f6f6 38%, #eeeded 54%);\n  background-size: 1000px 640px;\n  position: relative;\n  border-radius: 20px;\n}\n","$font-main: \"K4n-Regular\", sans-serif;\n\n$font-bold: \"K4n-Bold\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
