// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-header-logo {
  max-width: 140px;
  height: 50px;
}

.primary-header {
  background-color: var(--k4n-primary-color);
  padding: 30px 75px;
}

.flex-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.primary-navbar-division-green {
  width: 1px;
  border-right: 1px solid var(--k4n-primary-color);
  height: 2rem;
  margin: 0 30px;
}

.secondary-navbar-division-green {
  width: 1px;
  border-right: 1px solid var(--k4n-secondary-color);
  height: 2rem;
  margin: 0 30px;
}

.primary-header-link {
  font-family: "K4n-Regular", sans-serif;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.secondary-header-link {
  font-family: "K4n-Regular", sans-serif;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.primary-header-link:hover {
  color: var(--k4n-primary-color);
  text-decoration: underline;
}

.secondary-header-link:hover {
  color: var(--k4n-secondary-color);
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .primary-header-logo {
    max-width: 90px;
  }
  .primary-header {
    padding: 25px 10%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/features/Header/styles.scss","webpack://./src/assets/scss/_typography.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,YAAA;AADF;;AAIA;EACE,0CAAA;EACA,kBAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AADF;;AAIA;EACE,UAAA;EACA,gDAAA;EACA,YAAA;EACA,cAAA;AADF;;AAIA;EACE,UAAA;EACA,kDAAA;EACA,YAAA;EACA,cAAA;AADF;;AAIA;EACE,sCClCU;EDmCV,WAAA;EACA,qBAAA;EACA,eAAA;AADF;;AAIA;EACE,sCCzCU;ED0CV,WAAA;EACA,qBAAA;EACA,eAAA;AADF;;AAIA;EACE,+BAAA;EACA,0BAAA;AADF;;AAIA;EACE,iCAAA;EACA,0BAAA;AADF;;AAIA;EACE;IACE,eAAA;EADF;EAGA;IACE,iBAAA;EADF;AACF","sourcesContent":["@use \"../../../assets/scss/typography\";\n\n.primary-header-logo {\n  max-width: 140px;\n  height: 50px;\n}\n\n.primary-header {\n  background-color: var(--k4n-primary-color);\n  padding: 30px 75px;\n}\n\n.flex-space-between {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.primary-navbar-division-green {\n  width: 1px;\n  border-right: 1px solid var(--k4n-primary-color);\n  height: 2rem;\n  margin: 0 30px;\n}\n\n.secondary-navbar-division-green {\n  width: 1px;\n  border-right: 1px solid var(--k4n-secondary-color);\n  height: 2rem;\n  margin: 0 30px;\n}\n\n.primary-header-link {\n  font-family: typography.$font-main;\n  color: #fff;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.secondary-header-link {\n  font-family: typography.$font-main;\n  color: #fff;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.primary-header-link:hover {\n  color: var(--k4n-primary-color);\n  text-decoration: underline;\n}\n\n.secondary-header-link:hover {\n  color: var(--k4n-secondary-color);\n  text-decoration: underline;\n}\n\n@media screen and (max-width: 768px) {\n  .primary-header-logo {\n    max-width: 90px;\n  }\n  .primary-header {\n    padding: 25px 10%;\n  }\n}\n","$font-main: \"K4n-Regular\", sans-serif;\n\n$font-bold: \"K4n-Bold\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
