// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pix-receive-title-mobile {
  font-family: "K4n-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: var(--k4n-primary-color);
  margin-top: 28px;
}

.pix-receive-input-mobile {
  width: 100% !important;
  max-width: 312px !important;
  height: 67px !important;
  background: #fff2de !important;
  border: none !important;
  border-radius: 28px !important;
  margin-top: 40px;
}

.pix-receive-date-mobile {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  padding: 16px 0;
  align-items: center;
}

.pix-receive-content-keys-mobile {
  width: 100%;
  display: inline-flex;
  padding: 14px 0;
}

.justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/Pix/PixReceive/Mobile/styles.scss","webpack://./src/assets/scss/_typography.scss"],"names":[],"mappings":"AAGA;EACE,mCCFU;EDGV,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,gBAAA;AAFF;;AAKA;EACE,sBAAA;EACA,2BAAA;EACA,uBAAA;EACA,8BAAA;EACA,uBAAA;EACA,8BAAA;EACA,gBAAA;AAFF;;AAKA;EACE,WAAA;EACA,oBAAA;EACA,8BAAA;EACA,eAAA;EACA,mBAAA;AAFF;;AAKA;EACE,WAAA;EACA,oBAAA;EACA,eAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAFF","sourcesContent":["@use \"../../../../../../assets/scss/colors\";\n@use \"../../../../../../assets/scss/typography\";\n\n.pix-receive-title-mobile {\n  font-family: typography.$font-bold;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 32px;\n  line-height: 125%;\n  color: var(--k4n-primary-color);\n  margin-top: 28px;\n}\n\n.pix-receive-input-mobile {\n  width: 100% !important;\n  max-width: 312px !important;\n  height: 67px !important;\n  background: #fff2de !important;\n  border: none !important;\n  border-radius: 28px !important;\n  margin-top: 40px;\n}\n\n.pix-receive-date-mobile {\n  width: 100%;\n  display: inline-flex;\n  justify-content: space-between;\n  padding: 16px 0;\n  align-items: center;\n}\n\n.pix-receive-content-keys-mobile {\n  width: 100%;\n  display: inline-flex;\n  padding: 14px 0;\n}\n\n.justify-center {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n","$font-main: \"K4n-Regular\", sans-serif;\n\n$font-bold: \"K4n-Bold\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
