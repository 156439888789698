// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-account-container {
  background-color: var(--k4n-primary-color);
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding-top: 100px;
}

.warning-account-h1 {
  font-family: "K4n-Regular", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  color: var(--k4n-secondary-color);
}

.warning-account-title {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  max-width: 742px;
  margin-bottom: 60px;
}

.warning-account-flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 25px;
}

.warning-error-icon-space {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: linear-gradient(226.39deg, rgba(255, 242, 222, 0.7) 15.37%, rgba(255, 242, 222, 0.19) 85.86%);
}

.warning-error-exclamation-div {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  position: relative;
  top: 19px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/AccountOpeningError/styles.scss","webpack://./src/assets/scss/_typography.scss"],"names":[],"mappings":"AAEA;EACE,0CAAA;EACA,WAAA;EACA,iBAAA;EACA,SAAA;EACA,kBAAA;AADF;;AAIA;EACE,sCCXU;EDYV,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,iCAAA;AADF;;AAIA;EACE,cAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yGAAA;AADF;;AAQA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;AALF","sourcesContent":["@use \"../../../assets/scss/typography\";\n\n.warning-account-container {\n  background-color: var(--k4n-primary-color);\n  width: 100%;\n  min-height: 100vh;\n  margin: 0;\n  padding-top: 100px;\n}\n\n.warning-account-h1 {\n  font-family: typography.$font-main;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 48px;\n  line-height: 120%;\n  text-align: center;\n  color: var(--k4n-secondary-color);\n}\n\n.warning-account-title {\n  margin: 0 auto;\n  width: 100%;\n  text-align: center;\n  max-width: 742px;\n  margin-bottom: 60px;\n}\n\n.warning-account-flex-center {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  margin-top: 40px;\n  margin-bottom: 25px;\n}\n\n.warning-error-icon-space {\n  width: 88px;\n  height: 88px;\n  border-radius: 50%;\n  background: linear-gradient(\n    226.39deg,\n    rgba(255, 242, 222, 0.7) 15.37%,\n    rgba(255, 242, 222, 0.19) 85.86%\n  );\n}\n\n.warning-error-exclamation-div {\n  width: 50px;\n  height: 50px;\n  margin: 0 auto;\n  position: relative;\n  top: 19px;\n}\n","$font-main: \"K4n-Regular\", sans-serif;\n\n$font-bold: \"K4n-Bold\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
