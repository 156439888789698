import "../styles.scss"
import { useContext } from "react"
import { OnboardingContext } from "../../../../context/OnboardingContext"
import woman from "../../../../assets/images/woman-with-smartphone.png"

const PhotoLanding = () => {
  const { goBack, startIdentity } = useContext(OnboardingContext)

  return (
    <div className="gray-bg">
      <div
        className="corner-image-bg"
        style={{ backgroundImage: `url(${woman})` }}
      >
        <div className="dark-overlay">
          <div className="start-text-container ml-60px h-100 d-flex flex-column flex-justify-content-center position-relative">
            <button
              className="onboarding-go-back-white position-absolute top-0 mt-60px"
              onClick={goBack}
            />
            <div className="start-text-title mb-3">Tire uma selfie!</div>
            <div className="mb-4" style={{ fontSize: `1.5rem` }}>
              Vamos validar sua identidade.
            </div>
            <button className="start-button mb-3" onClick={startIdentity}>
              Tudo pronto!
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhotoLanding
