import "../styles.scss"
import { ChangeEvent, useContext, useState } from "react"
import { OnboardingContext } from "../../../../context/OnboardingContext"
import woman from "../../../../assets/images/smiling-woman.png"

const AddressLanding = () => {
  const { goBack, startAddress, getAddressFromPic } =
    useContext(OnboardingContext)

  const [loading, setLoading] = useState(false)

  const handleSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const files = e.target.files
    if (files && files[0]) {
      const p = files[0]
      if (p?.type?.includes(`image`)) {
        const res = await toBase64(p)
        await getAddressFromPic(res)
      } else {
        console.log(`not a image`)
      }
    }
    setLoading(false)
  }

  const toBase64 = async (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  return (
    <div className="gray-bg">
      <div
        className="corner-image-bg"
        style={{ backgroundImage: `url(${woman})` }}
      >
        <div className="dark-overlay">
          <div className="start-text-container ml-60px h-100 d-flex flex-column flex-justify-content-center position-relative">
            <button
              className="onboarding-go-back-white position-absolute top-0 mt-60px"
              onClick={goBack}
            />
            <div className="mt-5"></div>
            <div className="start-text-title mb-5 mt-5">
              Ok, tudo certo! Agora vamos validar seu endereço.
            </div>

            <button
              disabled={loading}
              className={`start-button mb-5 mt-5` + (loading ? ` loading` : ``)}
              onClick={startAddress}
            >
              Tudo pronto!
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddressLanding
