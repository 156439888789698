// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pix-card-mfe {
  margin-top: 96px;
  width: 100%;
  height: 687px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 68px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Banking/cards/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,4CAAA;EACA,mBAAA;EACA,aAAA;AACF","sourcesContent":[".pix-card-mfe {\n  margin-top: 96px;\n  width: 100%;\n  height: 687px;\n  background: #ffffff;\n  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);\n  border-radius: 16px;\n  padding: 68px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
