import { AxiosError } from "axios"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { InsuranceContext } from "../../../../../../context/InsuranceContext"
import services from "../../../../../../services/svcmesh"
import InsuranceStep1 from "../../purchase/step1"
import InsuranceStep2 from "../../purchase/step2"
import { InsuranceStep3 } from "../../purchase/step3"
import { InsuranceStep4 } from "../../purchase/step4"
import Renew from "../../renovar"
import "../styles.scss"

//qualquer modificaçao aqui exige modificaçao no desktop

const ProductMobile = () => {
  const { insuranceStep } = useContext(BankingNavigationContext)
  const {
    hasBeneficiary,
    addActivePlan,
    addActiveProposal,
    renew,
    addRenew,
    searchedForActiveProposal,
    addSearchedForActiveProposal,
  } = useContext(InsuranceContext)
  const { user } = useContext(AuthContext)
  const [loadingProposal, setLoadingProposal] = useState(false)

  useEffect(() => {
    if (!searchedForActiveProposal) searchForActiveProposal()
  }, [searchedForActiveProposal])

  const searchForActiveProposal = async () => {
    setLoadingProposal(true)
    if (user && user.id && user.cpf) {
      try {
        const res = await services.bankingService.getCustomerInsurance(
          user.id,
          user.cpf,
        )
        if (res && res.data) {
          addActiveProposal(res.data)
          setLoadingProposal(false)
        }
        console.log(res)
        if (res && res.data && res.data.plan) {
          addActivePlan(res.data.plan)
          addSearchedForActiveProposal(true)
          addRenew(true)
        }
      } catch (error) {
        const err = error as AxiosError
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 404
        ) {
          addSearchedForActiveProposal(true)
          addRenew(false)
          addActiveProposal({})
          setLoadingProposal(false)
        }
      }
    }
  }

  return (
    <div>
      {loadingProposal ? (
        <div className="spinner-container-mobile">
          <div className="spinner-border spinner-proposal" role="status" />
        </div>
      ) : (
        <>
          {renew ? (
            <Renew />
          ) : (
            <>
              {hasBeneficiary ? (
                <>
                  {insuranceStep === 1 && <InsuranceStep1 />}
                  {insuranceStep === 2 && <InsuranceStep2 />}
                  {insuranceStep === 3 && <InsuranceStep3 />}
                  {insuranceStep === 4 && <InsuranceStep4 />}
                </>
              ) : (
                <>
                  {insuranceStep === 1 && <InsuranceStep1 />}
                  {insuranceStep === 2 && <InsuranceStep3 />}
                  {insuranceStep === 3 && <InsuranceStep4 />}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ProductMobile
