// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-overlay-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--k4n-primary-color);
}

.loading-overlay-bg.default {
  /* background-color: colors.\$primary-green; */
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/features/LoadingOverlay/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,0CAAA;AADF;;AAIA;EACE,6CAAA;EACA,uBAAA;AADF","sourcesContent":["@use \"../../../assets/scss/colors\";\n\n.loading-overlay-bg {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  min-height: 100vh;\n  background-color: var(--k4n-primary-color);\n}\n\n.loading-overlay-bg.default {\n  /* background-color: colors.$primary-green; */\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
