import { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { OnboardingContext } from "../../../context/OnboardingContext"
import { useResposive } from "../../../hooks/useResponsive"
import OnboardingSideMenu from "../../features/OnboardingSideMenu"
// Address
import AddressCam from "./address/cam"
import AddressForm from "./address/form"
import AddressLanding from "./address/landing"
// Pages
import Alias from "./alias"
import Birthday from "./birthday"
// import Cpf from "./cpf"
import Email from "./email"
import Finish from "./finish"
import IdentityCam from "./identity/cam"
// Identity
import IdentityLanding from "./identity/landing"
import IdentityTips from "./identity/tips"
// import IsPublic from "./is-public"
import Name from "./name"
import Phone from "./phone"
import PhoneVerify from "./phone-verify"
import PhotoCam from "./photo/cam"
// import Benefits from "./benefits";
// Photo
import PhotoLanding from "./photo/landing"
import Welcome from "./welcome"

export const onboardingNavigation = {
  0: [<Welcome key="0" />],
  1: [<Name key="first-1" />, <Alias key="second-1" />],
  /* 2: [<IsPublic key="2" />], 
  3: [<Cpf key="3" />], */
  2: [<PhotoLanding key="first-3" />, <PhotoCam key="second-3" />],
  3: [<Birthday key="5" />],
  4: [
    <Email key="first-6" />,
    <Phone key="second-6" />,
    <PhoneVerify key="third-6" />,
  ],
  5: [
    <IdentityLanding key="first-7" />,
    <IdentityTips key="second-7" />,
    <IdentityCam key="third-7" />,
  ],
  6: [<AddressLanding key="8" /> /*<AddressCam key="second-8" />*/],
  7: [<AddressForm key="9" />],
  8: [<Finish key="10" />],
  /* 10: [<Benefits />],
    11: [<Finish />] */
}

const Onboarding = () => {
  const { step, subStep, saveCpf } = useContext(OnboardingContext)

  const location = useLocation()
  const { isTabletOrMobile } = useResposive()

  useEffect(() => {
    const cpf = (location.state as any)?.cpf
    if (cpf) {
      saveCpf(cpf)
    } else {
      window.location.href = `/` // Using standard navigation to clear React state
    }
  }, [])

  const pageSwitch = () => {
    return onboardingNavigation[step][subStep]
  }

  if (isTabletOrMobile)
    return <div className="onboarding-mobile-content">{pageSwitch()}</div>

  return <OnboardingSideMenu step={step}>{pageSwitch()}</OnboardingSideMenu>
}

export default Onboarding
